/*slider starts here*/
.style-ul {
    list-style: none;
    margin: 0px;
    padding-left: 0px;
  }
  
  .header-all {
    font-size: 32px;
    font-weight: 600;
    color: #687188;
  }
  
  .main-block {
    padding: 30px 15px;
  }
  .subHead-all {
    font-size: 19px;
    font-weight: 400;
    color: #848484;
    padding-bottom: 15px;
  }
  
  .lg-blue-bg {
    border-radius: 10px;
    background: #f3f4ff;
    padding: 12px 20px;
    height: 100px;
  }
  
  .lg-blue-bg ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  
  .elem-assure {
    display: flex;
    align-items: center;
  }
  
  .elem-assure span {
    background: #fff;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  
  .elem-assure img {
    width: 73px;
  }
  
  .elem-assure h5 {
    font-size: 14px;
    margin: 0px;
    padding: 0px 0 0 20px;
    font-weight: 500;
    color: #343434;
  }
  
  .assure-block span {
    background: transparent;
  }
  
  .assure-block img {
    width: 116px;
  }
  
  .assure-block {
    height: 73px;
  }
  
  .assure-block span {
    height: auto;
    width: auto;
  }
  
  .lg-blue-bg ul li:nth-child(1) img {
    width: 73px;
  }
  
  .lg-blue-bg ul li:nth-child(2) img {
    width: 73px;
    margin-top: -4px;
  }
  
  .lg-blue-bg ul li:nth-child(4) img {
    width: 73px;
    margin-top: -2px;
  }
  
  .lg-blue-bg ul li:nth-child(5) img {
    width: 73px;
    margin-top: -2px;
  }
  
  .backdrop.show {
    display: block;
    transition: 0.3s;
  }
  
  .backdrop {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    top: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    z-index: 101;
  }
  
  .dropdown-backdrop {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    top: 0px;
    width: 100%;
    height: 100vh;
    display: none;
    z-index: 10;
    transition: 0.3s;
  }
  
  .service-cm-faqs {
    margin: 0px 0px -100px 0px;
  }
  
  .sliderblock {
    padding: 45px 0px;
  }
  
  .slide-hm {
    margin-top: -461px;
  }
  
  .ovelapped-div-hm {
    z-index: 1;
  }
  
  .slider-image img {
    width: 100%;
    /* height: 500px; */
    /* object-fit: cover; */
  }
  
  .slider-image img {
    object-fit: contain;
  }
  
  .slider-image {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .slider-block {
    padding: 20px 0px;
  }
  
  .slider-block .slick-list {
    border-radius: 10px;
  }
  
  .service-block-home {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 25px 25px rgba(0, 0, 0, 0.07);
  }
  
  .service-block-home {
    margin-right: 43px;
    padding: 10px;
  }
  
  .service-block-home ul {
    display: flex;
    flex-wrap: wrap;
  }
  
  .service-block-home ul li {
    width: 25%;
    text-align: center;
  }
  
  .service-block-home ul li strong {
    display: block;
    color: #343434;
    font-size: 13px;
    font-weight: 500;
  }
  
 
  .service-block-home ul li:nth-child(1) img {
    width: 63px !important;
  }
  .service-block-home ul li:nth-child(2) img {
    width: 60px !important;
  }
  .service-block-home ul li:nth-child(6) img {
    width: 67px !important;
  }
  .service-block-home ul li:nth-child(7) img {
    width: 65px !important;
  }
  .service-block-home ul li:nth-child(9) img {
    width: 86px !important;
    margin-top: -9px;
  }
  .service-block-home ul li:nth-child(10) img {
    width: 80px !important;
    margin-top: -3px;
  }
  .service-block-home ul li:nth-child(8) img {
    width: 61px !important;
  }
  
  .service-block-home ul li a {
    padding: 14px 5px;
    height: 109px;
    display: block;
  }
  
  .service-block-home li a img {
    width: 70px;
  }
 
  
  .service-block-home li span {
    height: 56px;
    display: block;
  }
  
  /* .service-block-home li:nth-child(4) img {
      width: 80px;
  } */
  
  .service-block-home li {
    border-right: 1px dashed #ccc;
    border-bottom: 1px dashed #ccc;
  }
  
  .service-block-home li:nth-child(4),
  .service-block-home li:nth-child(8),
  .service-block-home li:nth-child(12) {
    border-right: 0px;
  }
  
  .service-block-home li:nth-child(9),
  .service-block-home li:nth-child(10),
  .service-block-home li:nth-child(11),
  .service-block-home li:nth-child(12) {
    border-bottom: 0px;
  }
  
  .ovelapped-div-hm .head-sr-block h1 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
    line-height: 21px;
  }
  
  .ovelapped-div-hm .head-sr-block h5 {
    font-size: 32px;
    color: #0451be;
    font-weight: 600;
  }
  
  .head-sr-block {
    padding-left: 10px;
  }
  
  .service-block-home a:hover {
    transform: scale(1.1);
    text-decoration: none;
  }
  
  .service-block-home a {
    transition: transform 0.5s;
  }
  
  .ob-highlight {
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: space-evenly;
    justify-content: space-evenly;
    margin-bottom: 20px;
    border: 1px solid #0003;
  }
  
  .ob-imo {
    width: 70px;
    height: 70px;
    position: relative;
  }
  
  .inner-imo {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    margin: 0;
  }
  
  .inner-imo img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .ob-highlight h5 {
    font-size: 17px;
    font-weight: 500;
  }
  
  .ob-highlight h3 {
    font-size: 25px;
    font-weight: 600;
    color: #0451be;
  }
  
  .tp-selling {
    padding: 60px 0px 20px 0;
  }
  
  .top-selling-card,
  .top-selling-container,
  .top-selling-image-container {
    width: 100%;
    border-radius: 10px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
  }
  
  .img.top-selling {
    border-radius: 15px;
    height: 280px;
    object-fit: cover;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .skimage-block {
    display: block;
    overflow: hidden;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;
  }
  
  .skimage-block img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    border-radius: 10px;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#000),
      to(#231f2000)
    );
    background: -moz-linear-gradient(bottom, #000, #231f2000);
    background: linear-gradient(0deg, #000, #231f2000);
  }
  
  .product-discount {
    position: absolute;
    top: 17px;
    left: 0;
    background: #fff;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: -moz-box;
    display: flex;
    padding: 4px 7px;
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    font-weight: 500;
    color: #ff3201;
  }
  
  .product-discount img {
    height: 17px;
    margin-right: 5px;
  }
  
  .top-selling-details {
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    position: absolute;
    bottom: 10px;
    border-radius: 30px;
    padding: 7px;
    margin-left: 5px;
    z-index: 2;
  }
  
  .top-sell-text {
    color: rgb(255, 255, 255);
    margin: 1px;
    z-index: 4;
    font-weight: 500;
    font-size: 17px;
  }
  
  .top-sell-text-yl {
    color: rgb(255, 241, 0);
    margin: 1px;
    z-index: 4;
    font-weight: 600;
    font-size: 16px;
  }
  
  .top-selling-head {
    position: relative;
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    transition: all 0.75s ease;
    padding: 15px;
  }
  
  .top-selling-image-container {
    position: relative;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  .outerskin-block {
    padding: 15px;
  }
  
  .tpselling-slider .slick-prev:before,
  .tpselling-slider .slick-next:before {
    color: #7f7f7f !important;
  }
  
  .tpselling-slider {
    padding: 0px 20px;
  }
  
  .top-selling-card:hover {
    /* transform: scale(1.1); */
    text-decoration: none;
  }
  
  .top-selling-card {
    transition: transform 0.5s;
  }
  
  .star-skn-block {
    display: inline-block;
  }
  
  .stars {
    position: relative;
    white-space: nowrap;
    margin-top: -4px;
    padding: 0px 2px;
  }
  
  .stars svg {
    width: 18px;
  }
  
  .cover {
    background: white;
    height: 100%;
    overflow: hidden;
    mix-blend-mode: color;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  /* svg {
      fill: #ffba07;
  } */
  
  .review-section {
    padding: 60px 0px;
  }
  
  .purple {
    color: #8a3c90;
    text-decoration: underline;
  }
  
  .rating-blk .purple {
    padding-left: 2px;
    margin-top: -2px;
    display: inline-block;
  }
  
  .google-icon {
    position: absolute;
    left: -10px;
    width: 230px;
  }
  
  .google-head {
    position: relative;
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    transition: all 0.75s ease;
    padding: 45px 20px;
  }
  
  .google-card {
    height: auto;
    width: 100%;
    border-radius: 30px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
  
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgb(0 0 0/10%);
    box-shadow: 0 0 10px rgb(0 0 0/10%);
    padding: 10px;
    -webkit-transition: all 0.75s ease;
    -moz-transition: all 0.75s ease;
    transition: all 0.75s ease;
    display: -moz-box;
    display: flex;
    -moz-box-pack: start;
    justify-content: flex-start;
    -moz-box-align: start;
    align-items: flex-start;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    cursor: pointer;
  }
  
  .google-container,
  .google-image-container {
    width: 100%;
    border-radius: 30px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
  }
  
  .google-container {
    height: auto;
    padding-top: 10px;
  }
  
  .google-text {
    font-weight: 400;
    color: rgb(0, 0, 0);
    margin-right: 20px;
    text-align: left;
    min-height: 150px;
  }
  
  .displayed-text {
    font-size: 14px;
  }

  .custom-text-review{
    font-size: 13px !important;
  }
  
  .profile {
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 40px;
    overflow: hidden;
  }
  
  .pro-image {
    display: block;
    overflow: hidden;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;
  }
  
  .pro-image img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .pro-info {
    font-weight: 700 !important;
    color: rgb(0, 0, 0);
  }
  
  .pro-info-span {
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-size: 13px;
    margin-top: 0px;
    display: block;
  }
  
  .google-card:hover {
    transform: translateY(-20px);
  }
  
  .pro-info {
    min-height: auto;
  }
  
  .review-section .slick-prev:before,
  .review-section .slick-next:before {
    color: #ccc;
  }
  
  .mb-mec-image img {
    position: absolute;
    bottom: 0;
    max-width: 100%;
  }
  
  .mb-mec-image img:nth-child(1) {
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: 8;
  }
  
  .mb-mec-image img:nth-child(3) {
    position: absolute;
    bottom: 0;
    z-index: 6;
    left: 12%;
  }
  
  .mb-mec-image img:nth-child(4) {
    position: absolute;
    bottom: 6px;
    right: 10%;
    z-index: 7;
  }
  
  .mb-mec-image img:nth-child(5) {
    display: flex;
    position: absolute;
    bottom: 15px;
    right: 11%;
    z-index: 8;
  }
  
  .mobile-mechanice-title h2,
  .oba-container.mobile-view {
    display: none;
  }
  
  .otp-button {
    background: linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    border: none;
    min-height: 45px;
  }
  
  .mob-links {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #c2c2c2;
    padding: 35px 25px 7px;
  }
  
  .mobile-mechenic .one-line {
    white-space: nowrap;
    color: #000;
    margin: auto 5px;
    background: #e6f1f7;
    margin: 3px 0px;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 15px;
  }
  
  .blue-gradient {
    background: linear-gradient(#0c1892, #0451be);
    border-radius: 15px;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 5px solid #0451be;
  }
  
  .offer-head {
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-size: 30px;
  }
  
  .service-contract-offer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
  }
  
  .offer-text-left {
    width: 60%;
    font-weight: 300;
    color: #fff;
    text-align: left;
    line-height: 35px;
    list-style: initial;
  }
  
  .car-detail {
    width: 40%;
    background: #f0f0f0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .car-detail .offer-text {
    text-align: center;
    color: #000;
  }
  
  .org-txt {
    color: rgb(245, 139, 31);
    font-weight: 700;
  }
  
  .offer-text strong {
    font-weight: 800;
  }
  
  .offer-text-block {
    text-align: center;
    font-size: 17px;
    width: 100%;
  }
  
  .view-price-custom {
    display: block;
    padding: 10px 0px;
    font-size: 14px;
    width: 100%;
    color: #ff3201;
  }
  
  .offer-price {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
  }
  
  .button-offer {
    background: linear-gradient(0deg, rgba(4,81,190,1) 0%, rgba(12,24,146,1) 100%);
    text-align: center;
    color: #fff;
    border-radius: 30px;
    padding: 8px 20px;
    border: none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
    font-weight: 500;
    font-size: 16px;
  }
  
  .button-offer-one {
    width: 100%;
    background: #000;
    color: #fff;
    padding: 5px;
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    height: 40px;
    text-align: center;
    border-radius: 20px;
    margin: 5px 10px;
  }
  
  .b3g1 {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#9e5624),
      to(#efc384)
    );
    background: -moz-linear-gradient(#9e5624, #efc384);
    background: linear-gradient(#9e5624, #efc384);
    background-position: 50%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    border: 5px solid #ff3201;
    padding: 20px;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
  }
  
  .mobile-mechenic {
    padding: 60px 0px;
  }
  
  .text-yellow {
    color: #fff200;
  }
  
  .preview-img {
    width: 100%;
    height: 200px;
    position: relative;
  }
  
  .prev-inner {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    margin: 0;
  }
  
  .prev-inner img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .offer-section {
    padding: 0px 0px 60px;
  }
  
  .partner-slide {
    -webkit-box-shadow: 0 4px 12px hsla(0, 0%, 62%, 0.25);
    box-shadow: 0 4px 12px hsla(0, 0%, 62%, 0.25);
    padding: 15px;
    margin: 15px;
    border-radius: 20px;
  }
  
  .partnersl-1 img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  
  .partnersl-1 {
    display: block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    margin: 0px;
  }
  
  .partnersl-2 {
    display: block;
    box-sizing: border-box;
    padding-top: 55.7377%;
  }
  
  .pr-space-block {
  }
  
  .why-crd-1-skin {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }
  
  .why-crd-2 {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    margin: 0;
  }
  
  .why-crd-2 img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .card-why {
    height: 100%;
    width: 100%;
    background: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    border-radius: 20px;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    padding: 8px;
    -webkit-box-shadow: 0 4.64117px 18.5647px hsla(0, 0%, 62%, 0.25);
    box-shadow: 0 4.64117px 18.5647px hsla(0, 0%, 62%, 0.25);
    position: relative;
    min-height: 400px;
  }
  
  .card-why-mobile{
    box-shadow: none !important;
    border: 1px solid #ddd;
  }
  
  .why-crd-title {
    position: absolute;
    bottom: 0;
    color: #fff;
    background: -moz-linear-gradient(91deg, #414141e8, transparent);
    background: linear-gradient(359deg, #414141e8, transparent);
    padding: 80px 10px 15px;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .why-sl-img img {
    width: 100%;
    border-radius: 15px;
    height: 197px;
    object-fit: cover;
  }
  
  .slider-why {
    margin-bottom: 0px !important;
  }
  
  .slider-why .slick-dots {
    bottom: 11px;
  }
  
  .slider-why .slick-prev,
  .slider-why .slick-next {
    display: none;
  }
  
  .slider-why .slick-dots li button:before {
    font-size: 15px;
  }
  
  .slider-why .slick-dots li.slick-active button:before {
    color: #fff;
  }
  
  .why-text-sc li {
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    font-size: 14px;
    list-style: initial;
    padding-bottom: 10px;
  }
  
  .why-ob-list-style-none li{
    list-style: none !important;
  }
  
  .whob-txt h3 {
    font-size: 25px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 15px;
  }
  
  .partner-block-slide {
    padding: 0px 20px;
  }
  
  .partner-block-slide .slick-prev:before,
  .partner-block-slide .slick-next:before {
    color: #ccc;
  }
  
  .partner-section {
    padding: 30px 0px;
  }
  
  .why-lvl-11 img {
    border-radius: 12px;
    width: 100%;
  }
  
  .why-lvl-11 {
    padding-bottom: 15px;
  }
  
  .why-lvl-2 {
    text-align: center;
  }
  
  .why-lvl-2 img {
    width: 80%;
    margin: 0px auto;
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
  }
  
  .cstlv-slider {
    position: absolute;
    bottom: -1px;
    left: 10px;
    width: 100%;
  }
  
  .cstlv-slider .slick-dots li button:before {
    color: #fff !important;
  }
  
  .card-slide-block {
    padding: 50px 0px;
  }
  
  .brands-section {
    padding: 60px 0px 30px;
  }
  
  .bubble-img {
    position: absolute;
    left: -10px;
    z-index: -1;
  }
  
  .slide-lvup {
    display: flex;
  }
  
  .slide-lvup img {
    width: 60px;
  }
  
  .slide-lvup {
    padding: 0px 10px;
  }
  
  .slide-lvup .d-flex {
    align-items: center;
  }
  
  .slide-lvup .d-flex span {
    color: #fff;
    font-weight: 500;
    padding-left: 10px;
    font-size: 14px;
  }
  
  .brand-logo {
    height: 110px;
    width: 110px;
    border-radius: 70px;
    background: #fff;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    display: -moz-box;
    display: flex;
    -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    transition: all 1s ease;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .brand-logo-fs {
    width: 70px;
    position: relative;
    height: 70px;
  }
  
  .brand-logo-sc {
    display: block;
    overflow: hidden;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    margin: 0px;
  }
  
  .brand-logo-sc img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .brandtpicker {
    background: #fff;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
  }
  
  .slider-text-lup {
    margin-bottom: 37px;
  }
  .slider-text-lup .slick-next,
  .slider-text-lup .slick-prev {
    display: none !important;
  }
  
  .bootstrap-select > .dropdown-toggle.bs-placeholder {
    background: #fff;
  }
  
  .orange-btn-br {
    height: 38px;
    background:linear-gradient(0deg, rgba(247,146,34,1) 0%, rgba(255,50,1,1) 100%);
    color: #fff;
    border: 0px;
    border-radius: 4px;
    padding: 0px 10px;
    margin-left: 10px;
  }
  
  .search-brand {
    display: flex;
    justify-content: flex-end;
  }
  
  .br-logo-outer {
    padding: 10px;
  }
  
  .brand-scroll-first .slick-prev:before,
  .brand-scroll-first .slick-next:before {
    color: #3d3d3d !important;
  }
  
  .faqs-div {
    width: 100%;
    border-radius: 30px;
    background: #0451be;
    padding-bottom: 10px;
    padding: 20px;
  }
  
  .faqs-inner-block {
    border: 1px solid #fff;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  
  .faqs-inner-block .btn-link {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    padding: 0px;
  }
  
  .faqs-inner-block .para-text {
    color: #fff;
    font-size: 14px;
    padding: 0px 15px;
  }
  
  .heading-txt-acc {
    padding: 15px 10px;
  }
  
  .faqs-inner-block p {
    font-size: 13px;
    line-height: 20px;
  }
  
  .first-txt-head .mb-0:after {
    content: "\f106";
  }
  
  .faqs-section {
    padding: 60px 0px;
  }
  
  .accicon {
    float: right;
    font-size: 20px;
    width: 1.2em;
    padding-top: 3px;
    color: #fff;
  }
  
  .heading-txt-acc:not(.collapsed) .rotate-icon {
    transform: rotate(180deg);
  }
  
  .fqs-heade {
    padding: 20px 0px 20px;
  }
  
  .faqs-inner-block h5 {
    padding-left: 4px;
  }
  
  .view-more-faq {
    text-align: center;
  }
  
  .view-more-faq a {
    color: #fff;
    vertical-align: middle;
  }
  
  .review-section .slick-prev,
  .review-section .slick-next {
    display: none !important;
  }
  
  .google-container::before {
    content: "";
    background: url(/assets/home/quotes.svg) no-repeat;
    opacity: 0.4;
    width: 30px;
    height: 30px;
    position: absolute;
    background-size: 20px;
  }
  
  .google-container .pl-3 {
    padding-left: 23px !important;
  }
  .displayed-text {
    padding-bottom: 20px;
    padding-left: 10px;
    display: block;
  }
  
  .heading-common-font-size {
    font-size: 32px !important;
  }
  
  .sub-heading-common-font-size {
    font-size: 25px;
    color: #848484;
  }
  
  @media screen and (max-width: 960px) {
    .lg-blue-bg ul li {
      width: 50%;
    }
  
    .lg-blue-bg ul li img {
      width: 50px !important;
    }
  
    .elem-assure h5 {
      font-size: 13px;
      padding-top: 7px;
    }
  
    .elem-assure {
      display: block;
      text-align: center;
    }
  
    .elem-assure.assure-block span img {
      width: 143px !important;
      margin-top: -82px;
    }
  
    .elem-assure span {
      background: #fff;
      padding: 10px;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: inline-block;
    }
  
    .lg-blue-bg ul li:nth-child(5) img {
      margin-top: 3px;
      margin-left: 5px;
    }
  
    .elem-assure {
      margin-top: -5px;
    }
  
    .elem-assure.assure-block span {
      width: initial !important;
      height: initial !important;
      background: transparent;
    }
  
    .lg-blue-bg ul li:nth-child(4) img {
      margin-top: 6px;
      margin-left: 5px;
    }
  
    .lg-blue-bg ul li:nth-child(2) img {
      margin-top: 1px;
      margin-left: 1px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .service-cm-faqs {
      margin-bottom: -40px;
    }
  
    .top-selling-image-container {
      box-shadow: 0px 0px 0px 0px #fff;
    }
    .main-block {
      padding-left: 0px;
      padding-right: 0px;
    }
  
    .assureblick-li {
      position: absolute;
    }
  
    .desktop-assure {
      display: none;
    }
  
    .slick-slider .slick-prev,
    .slick-next {
      background-color: red !important;
      display: none !important;
    }
  
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  
    .lg-blue-bg {
      height: auto;
    }
  
    .elem-assure h5 {
      padding-left: 0px;
    }
  
    .lg-blue-bg ul li {
      padding-bottom: 24px;
    }
  
    .lg-blue-bg ul {
      flex-wrap: wrap;
      padding-top: 40px;
    }
  
    .bubble-img {
      width: 100%;
    }
  
    .ovelapped-div-hm {
      width: 100%;
      max-width: 100%;
      flex: 100%;
      margin-top: 12px;
    }
  
    .slider-image img {
      height: auto;
    }
  
    .slide-hm {
      width: 100%;
      max-width: 100%;
      flex: 100%;
      margin-left: 0px;
    }
  
    .slide-hm {
      order: -1;
      margin-top: initial !important;
      /* padding-left: 25px; */
    }
  
    .service-contract-offer {
      height: 100%;
      flex-wrap: wrap;
    }
  
    .offer-text-left {
      width: 100%;
    }
  
    .car-detail {
      width: 100%;
    }
  
    .mobile-mechanice-img {
      display: none;
    }
  
    .mb-mec-image img:nth-child(1) {
      width: 95%;
    }
  
    .faqs-inner-block .btn-link {
      white-space: inherit;
      text-align: left;
    }
  
    .sliderblock {
      padding: 0px 0 30px;
    }
  
    .mobile-mechenic {
      padding-bottom: 20px;
    }
  
    .brands-section {
      padding-top: 10px;
    }
  
    .br-logo-outer {
      margin: 0px auto;
      text-align: center;
      display: flex !important;
      justify-content: center;
    }
  
    .google-card {
      background: rgba(255, 255, 255, 0.7);
    }
  
    .review-section {
      padding: 50px 0px 40px;
    }
  
    .assure-section {
      display: none;
    }
  
    .mobile-mechenic .one-line {
      color: #425055;
      margin: 4px 7px;
      font-size: 14px;
    }
  
    .accicon {
      right: 39px;
      position: absolute;
    }
  
    .faqs-inner-block h5 {
      margin-right: 17px;
    }
  
    .blue-gradient {
      margin-bottom: 15px;
    }
  
    .assure-mobile {
      display: block !important;
      position: relative;
    }
  
    .header-all {
      font-size: 25px !important;
    }
  
    .card-why {
      margin-bottom: 30px;
      height: 330px;
    }
  
    .card-slide-block .col-md-4 {
      margin-bottom: 20px;
    }
  
    .subHead-all {
      font-size: 17px !important;
    }
  
    .service-block-home ul li a {
      height: auto;
    }
  
    .brands-section .container {
      overflow: hidden;
    }
  
    .mobile-mechanice-title h2 {
      display: block;
      text-align: center;
    }
  
    .mb-mec-image img {
      position: initial !important;
    }
  
    .mobile-mechanice-title h2 {
      font-size: 25px !important;
      font-weight: 600;
    }
  
    .req-btn {
      display: none;
    }
  
    .mobile-mechenic {
      padding-top: 10px;
    }
  
    .mobreq-link {
      display: block !important;
      text-align: center;
      margin: 30px auto 20px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .why-lvl-2 img {
      width: 60%;
    }
  
    .heading-common-font-size {
      font-size: 25px !important;
    }
   
    .sub-heading-common-font-size {
      font-size: 16px !important;
    }
  
    .cstlv-slider {
      bottom: -25px;
    }
  
    .faqs-inner-block .para-text {
      padding: 0px 5px;
      text-align: justify;
    }
  
    .heading-txt-acc {
      padding: 0px 15px 10px 0px;
    }
  
    .faqs-inner-block {
      /* border: 1px solid #fff; */
      border: none;
      border-radius: 0px;
      margin-bottom: 8px;
      border-bottom: 1px solid #fff;
    }
  
    .hide-slide-mobile{
      display: none;
    }
  
    .whob-txt h3 {
      padding-bottom: 0px;
      padding-top: 12px;
      font-size: 21px;
    }
  
    .why-sl-img img {
      height: 150px;
    }
  
    .why-text-sc li {
      padding-bottom: 5px;
      font-size: 13px;
    }
  
    .why-sl-img img {
      object-fit: cover;
    }
    .overlay {
      margin: 3px;
    }
  
    .top-selling-card,
    .top-selling-container,
    .top-selling-image-container {
      padding: 3px;
    }
  
    .mob-links {
      display: none;
    }
  
    .view-price-custom {
      display: block;
      padding: 10px 0px;
      font-size: 18px;
      width: 100%;
      color: #ff3201;
    }
  
    .for-mobile {
      display: block;
    }
  
    .subHead-all {
      padding-bottom: 3px !important;
    }
  
  
    .lg-blue-bg ul li:nth-child(1) img {
      width: 35px;
      margin-top: 8px;
    }
  
    .elem-assure.assure-block span img {
      width: 148px !important;
    }
  
    .header-all {
      font-size: 25px !important;
    }
  
    .sliderblock .ovelapped-div-hm h5 {
      padding-bottom: 6px;
    }
  
    .outerskin-block {
      padding: 0px;
    }
  
    .service-block-home {
      padding-top: 10px;
      margin-right: 0px;
    }
  
    .subHead-all {
      font-size: 16px !important;
      text-align: center;
    }
  
    .profile {
      margin-left: 10px;
    }
  
    .card-slide-block {
      padding: 13px 0px;
    }
  
    .main-block {
      padding: 8px 0px;
      margin-top: -8px;
    }
  
    .accicon {
      position: absolute;
      right: 38px;
    }
  
    .faqs-inner-block h5 {
      margin-right: 17px;
    }
  
    .google-head {
      padding: 4px 10px;
    }
  
    .google-icon {
      width: 70px;
      margin-top: 58px;
      left: 0px;
    }
  
    .google-container::before {
      background-size: 16px;
    }
  
    .tpselling-slider {
      padding: 0px;
    }
  
    .search-brand .dropdown {
      width: 100% !important;
    }
  
    .faqs-section {
      padding: 20px 0px;
    }
  
    .main-footer {
      margin-top: 30px;
    }
  
    .kt-featured {
      /* margin-top: 20px; */
    }
  
    .tp-selling {
      padding: 30px 0px 20px 0;
    }
  
    .offer-section .col-lg-7 {
      margin-bottom: 20px;
    }
  
    .sliderblock {
      padding: 4px 0px;
    }
  
    .slider-block {
      padding: 6px 0px;
    }
  
    .offer-section {
      padding-bottom: 10px;
    }
  
    .assureblick-li {
      top: 8px;
    }
  
    .partner-section {
      padding: 24px 0px;
    }
  
    .service-block-home li span {
      height: auto;
    }
  
    .service-block-home ul li a {
      padding-bottom: 0px;
    }
  
    .service-block-home li span {
      border-radius: 15px;
      background: #f3f9ff;
      text-align: center;
      line-height: 65px;
      margin-bottom: 10px;
      height: 72px !important;
    }
  
    .reqcall span {
      background: #fff9d3 !important;
    }
  
    .reqcallstrong {
      color: #b69d00 !important;
    }
  
    .service-block-home li {
      border: 0px;
    }
  
    .service-block-home ul li {
      width: 33.33%;
    }
  
    .ob-highlight {
      border: none;
      padding: 7px;
      margin-bottom: 5px;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      flex-direction: column;
    }
  
    .ob-imo {
      height: 45px !important;
      width: 45px !important;
    }
  
    .ob-highlight h3 {
      font-size: 15px;
      margin-top: 10px;
    }
  
    .ob-highlight h5 {
      font-size: 12px;
    }
  
    .right-tp-header ul li {
      padding-right: 1px;
    }
  
    .service-block-home li a img {
      width: 74px;
      margin-top: -4px;
    }
  
    .service-block-home li:nth-child(2) a img {
      width: 37px;
    }
  
    .service-block-home li:nth-child(10) a img {
      width: 88px;
      margin-top: -9px;
    }
    .service-block-home li:nth-child(12) a img {
      width: 66px;
    }
    .service-block-home li:nth-child(4) a img {
      width: 55px;
    }

    .service-block-home ul li:nth-child(7) img {
      width: 72px !important;
      margin-top: 0px;
    }
  
    .wp-sb {
      width: 100%;
      margin-top: 13px;
    }
  
    /* .service-block-home li:nth-child(4) img {
          width: 60px;
      } */
  
    .ovelapped-div-hm .head-sr-block h1 {
      font-size: 19px;
      margin-bottom: 3px;
    }
  
    .ovelapped-div-hm .head-sr-block h5 {
      font-size: 25px;
    }
  
    .nr-rght img {
      width: 100px;
    }
  
    .brand-logo {
      height: 90px;
      width: 92px;
    }
  
    .mobile-mechenic .one-line {
      margin: 2px;
      font-size: 14px;
      padding: 4px 7px;
    }
  }
  
  .main-new-heading {
    color: #687188 !important;
  }
